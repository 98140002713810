import { graphql } from 'gatsby';
import React from 'react';
import BuyapowaEmbed from 'components/BuyapowaEmbed';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { CsHero } from 'types/contentStack';

type ReferAFriendProps = {
  data: {
    csPetReferAFriend: {
      meta_title: string;
      hero: CsHero;
    };
  };
};

export const query = graphql`
  query {
    csPetReferAFriend {
      meta_title
      hero {
        heading
        subheading
      }
    }
  }
`;

const ReferAFriend: React.FC<ReferAFriendProps> = ({
  data: {
    csPetReferAFriend: { hero, meta_title },
  },
}) => {
  usePageTracking(meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking();

  return (
    <Layout
      pageTitle={PageTitle.ReferAFriend}
      heading={hero.heading}
      subheading={hero.subheading}
      metaTitle={meta_title}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      <BuyapowaEmbed />
    </Layout>
  );
};

export default ReferAFriend;
