import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BuyapowaContainer, BuyapowaErrorContainer, Divider, Loader } from './styles';
import { TrackingEvent } from '../../helpers/eventTracking';
import { booleanAttribute } from '../Analytics/booleanAttribute';

const BUYAPOWA_MARKET_DOMAIN = 'https://bp.tescobank.com';
const BUYAPOWA_CAMPAIGN_SLUG = 'tescobpi_raf1';
const BUYAPOWA_MARKET = 'tescobpi';
const BUYAPOWA_LOCALE = 'en';
const BUYAPOWA_CONTAINER_ID = 'bp_div';
const BUYAPOWA_ERROR_CONTAINER_ID = 'bp_error';
const BUYAPOWA_EMBED_SCRIPT_URL = '//cdn.co-buying.com/embedding.min.js';

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

type BuyapowaErrorProps = {
  csBuyapowaErrors: {
    buyapowa_internal_error: string;
    buyapowa_cookie_consent_error: string;
  };
};

export const query = graphql`
  query {
    csBuyapowaErrors {
      buyapowa_internal_error
      buyapowa_cookie_consent_error
    }
  }
`;

declare global {
  interface Window {
    Buyapowa: BuyapowaConstructable;
    dataLayer?: TrackingEvent[];
  }
}

interface Buyapowa {
  embedReferAFriend: (campaignDetails: { campaign: string; locale: string }) => void;
}

interface BuyapowaConstructable {
  new (
    campaignDetails: { url: string; market: string },
    divId: string,
    errorDivId: string
  ): Buyapowa;
}

const BuyapowaEmbed: React.FC = () => {
  const errorProps = useStaticQuery<BuyapowaErrorProps>(query).csBuyapowaErrors;

  // Depending on the user's cookie preferences, it is possible that the Buyapowa embed script will be prevented
  // from running. We're unable to directly access the cookie preferences, so the logic to show the appropriate error
  // message is as follows:
  //   1. Page loads, nothing shown
  //   2. After a 1500ms timeout, if the embed script has downloaded and run successfully, window.Buyapowa is defined so
  //      the div containing the error message remains hidden. Otherwise, it is shown.
  //   3. In the unlikely case that the embed script takes more than 1500ms to download and execute, there is also a toggle
  //      to explicitly hide the error div in the script completion event listener.
  const [showCookieError, setShowCookieError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const cookieConsented = (): boolean => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      return window?.dataLayer.some((analyticsEvent) =>
        analyticsEvent?.OptanonActiveGroups?.includes('C0003')
      );
    }
    return false;
  };
  const [cookieConsent, setCookieConsent] = useState(cookieConsented());

  const displayBuyapowa = (): void => {
    const buyapowa = new window.Buyapowa(
      { url: BUYAPOWA_MARKET_DOMAIN, market: BUYAPOWA_MARKET },
      BUYAPOWA_CONTAINER_ID,
      BUYAPOWA_ERROR_CONTAINER_ID
    );
    buyapowa.embedReferAFriend({
      campaign: BUYAPOWA_CAMPAIGN_SLUG,
      locale: BUYAPOWA_LOCALE,
    });
    setShowCookieError(false);
  };

  useEffect(() => {
    if (activeEnv === 'test') {
      return;
    }

    const checkCookieConsent = setInterval(() => {
      const consented = cookieConsented();
      if (consented) {
        setCookieConsent(consented);
        clearInterval(checkCookieConsent);
      }
    }, 900);

    if (cookieConsent) {
      setShowLoader(true);
      setTimeout(() => {
        if (typeof window?.Buyapowa !== 'undefined') {
          setShowLoader(false);
          displayBuyapowa();
        }
      }, 900);
    } else {
      setShowCookieError(true);
    }
  }, [cookieConsent]);

  return (
    <>
      <Helmet>
        <script
          data-rh="true"
          className="optanon-category-C0003"
          type="text/plain"
          src={BUYAPOWA_EMBED_SCRIPT_URL}
          async={booleanAttribute}
          defer={booleanAttribute}
        />
        <meta
          property="og:url"
          content={`${BUYAPOWA_MARKET_DOMAIN}/iaf/${BUYAPOWA_CAMPAIGN_SLUG}/og`}
        />
      </Helmet>
      {showLoader && (
        <Loader data-cy="buyapowa-loader">
          <RichText html="Loading..." />
        </Loader>
      )}
      {showCookieError && (
        <div>
          <RichText html={errorProps.buyapowa_cookie_consent_error} />
        </div>
      )}
      <BuyapowaErrorContainer id={BUYAPOWA_ERROR_CONTAINER_ID}>
        <RichText html={errorProps.buyapowa_internal_error} />
      </BuyapowaErrorContainer>
      <BuyapowaContainer id={BUYAPOWA_CONTAINER_ID} />
      <Divider />
    </>
  );
};

export default BuyapowaEmbed;
