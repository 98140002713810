import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const BuyapowaContainer = styled.div`
  :empty {
    display: none;
  }
  min-height: 800px;
`;

export const BuyapowaErrorContainer = styled.div`
  display: none;
`;

export const Divider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(8)};
`;

export const Loader = styled.div`
  margin: 1rem;
  text-align: center;
`;
